import { render, staticRenderFns } from "./Storecomment.vue?vue&type=template&id=4ac03571&scoped=true&"
import script from "./Storecomment.vue?vue&type=script&lang=js&"
export * from "./Storecomment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac03571",
  null
  
)

export default component.exports